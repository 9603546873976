'use strict';

// Include base block
var Block = require('../libs/block');

// Create new block
module.exports = Block.extend({
    template: '#invoicing',
    init: function () {
        $('#request-run-button').click(function(event) {
            if (window.confirm(gettext('Do yo want to run this request?', 'integration.Request'))) {
                var pk = $(event.target).data('requestPk');
                Notify.success(
                    gettext('Integration Request', 'integration.Request'),
                    gettext('Request successfully started!', 'integration.Request')
                )
                TCApi.call('run_integration_request', { request_pk: pk }, false).then(function(e) {
                    Notify.success(
                        gettext('Integration Request', 'integration.Request'),
                        gettext('Request successfully completed!', 'integration.Request')
                    )
                });
            }
        });
        
        function callIntegrationRequestApi(button, endpoint) {
            var requestPk = button.getAttribute('data-request-pk');
            button.disabled = true; 
            button.innerText = '';
            var icon = document.createElement('i');
            icon.className = 'fa fa-spinner fa-spin';
            button.appendChild(icon);

            TCApi.call(endpoint, {request_pk: requestPk}, false).then(function(e) {
                var parent = button.parentElement;
                parent.removeChild(button);
                parent.innerText = e;
            });
        };

        $('#lazy-load-request-response-content').click(function(e) {
            callIntegrationRequestApi(e.target, 'get_integration_request_response_content');
        });

        
        $('#lazy-load-request-handler-output').click(function(e) {
            callIntegrationRequestApi(e.target, 'get_integration_request_handler_output');
        });

        $('#request-re-process-xml-button').click(function(event) {
            if (window.confirm(gettext('Do yo want to re-process the XML of this request?', 'integration.Request'))) {
                var pk = $(event.target).data('requestPk');
                Notify.success(
                    gettext('Integration Request', 'integration.Request'),
                    gettext('Request successfully started!', 'integration.Request')
                )
                TCApi.call('re_process_integration_request_xml', { request_pk: pk }, false).then(function(e) {
                    Notify.success(
                        gettext('Integration Request', 'integration.Request'),
                        gettext('Request successfully completed!', 'integration.Request')
                    )
                });
            }
        });
    }
});
