/*globals Notify, Confirm, BluugoBrowser, TCApi */
'use strict';

// Include base block
var Block = require('../libs/block');

// Create new block
module.exports = Block.extend({
    template: '#example-view',

    /**
     * Basic Block-initialization
     */
    init: function () {
        if (window.is_bluugo_browser) {
            this.initBluugoBrowser();
        }
    },

    /**
     * This function is called if BluugoBrowser is detected
     */
    initBluugoBrowser: function () {
        var name = null,
            plugin = null,
            html = null;
        // Make sure that client script is actually available
        if (BluugoBrowser.isAvailable()) {

            // Print all available plugins to the screen
            for (name in BluugoBrowser.info.plugins) {
                if (BluugoBrowser.info.plugins.hasOwnProperty(name)) {
                    plugin = BluugoBrowser.info.plugins[name];
                    html = '<details><summary style="font-size: 1.3em;">' + name + '</summary>';
                    for (name in plugin) {
                        if (plugin.hasOwnProperty(name)) {
                            html += '<strong>' + name + '</strong> ' + plugin[name] + '</br>';
                        }
                    }
                    this.hooks.pluginsInfo.append(html + '</details>');
                }
            }
        }

        // Initialize Fn-buttons demo
        this.initFnListener();

        // Initialize NFC demo if adapter is available
        this.initNfcListener();
    },

    /**
     * Initializes Fn-buttons demo
     */
    initFnListener: function () {
        // Get "general"-plugin reference
        // This plugin will provide some general functionality in future...
        this.generalPlugin = BluugoBrowser.plugin('general');
        if (this.generalPlugin.isAvailable()) {

            // Listen for "bb:fnclick"-event
            // This event is actually broadcasted to every active plugin
            this.generalPlugin.on('bb:fnclick', function (which) {
                switch (which) {
                case BluugoBrowser.BUTTON_FN1:
                    this.writeLog('Fn1-button clicked');
                    break;
                case BluugoBrowser.BUTTON_FN2:
                    this.writeLog('Fn2-button clicked');
                    break;
                case BluugoBrowser.BUTTON_FN3:
                    this.writeLog('Fn3-button clicked');
                    break;
                }
            }, this);
        }
    },

    /**
     * Initializes NFC demo
     */
    initNfcListener: function () {
        // Get a reference to NFC-plugin
        this.nfcPlugin = BluugoBrowser.plugin('nfc');
        if (this.nfcPlugin.isAvailable()) {
            // Show current adapter status
            this.hooks.nfcPlugin.removeClass('hidden');
            if (this.nfcPlugin.info.enabled) {
                // Start nfc-scanning if adapter is on
                this.nfcPlugin.call('start');
                this.hooks.nfcStatus.text('On');
            } else {
                this.hooks.nfcStatus.text('Off');
            }

            // React to nfc adapter state-event
            this.nfcPlugin.on('state', function (state) {
                switch (state) {
                case BluugoBrowser.STATE_NFC_ON:
                    this.nfcPlugin.call('start');
                    this.writeLog('NFC turning on');
                    this.hooks.nfcStatus.text('On');
                    break;
                case BluugoBrowser.STATE_NFC_OFF:
                    this.writeLog('NFC off');
                    this.hooks.nfcStatus.text('Off');
                    break;
                case BluugoBrowser.STATE_NFC_TURNING_ON:
                    this.writeLog('NFC turning on...');
                    this.hooks.nfcStatus.text('Turning on...');
                    break;
                case BluugoBrowser.STATE_NFC_TURNING_OFF:
                    this.nfcPlugin.call('stop');
                    this.writeLog('NFC turning off...');
                    this.hooks.nfcStatus.text('Turning off..');
                    break;
                }
            }, this);

            // React to new tags
            this.nfcPlugin.on('tag', function (tag) {
                if (navigator.vibrate) {
                    navigator.vibrate(150);
                }
                this.writeLog('NFC tag: ' + tag.id);
                this.hooks.nfcTag.text(tag.id);
            }, this);
        }
    },

    /**
     * Callback function for when the "Show flash message"-button is clicked
     * This callback is atteched to element via data-event-attribute in html
     * @param {jQuery.Event} e jQuery Click-event
     */
    showFlashMessages: function (e) {
        e.preventDefault();

        // Some example data to send to API
        var data = {
            param1: 'Foo',
            param2: true
        };
        var promise = null;

        // Randomly make public / private call
        if (Math.random() >= .5) {
            this.writeLog('Calling public API...');
            promise = TCApi.call('public_api_demo', data, true);
        } else {
            this.writeLog('Calling private API...');
            promise = TCApi.call('private_api_demo', data, false);
        }

        // Handle promise results

        promise.then(function () {
            this.writeLog('API call success.');
            location.reload();
        }.bind(this), function (e) {
            this.writeLog('API call failed: ' + e.error);
            Notify.error('Error while requesting API', e.error);
        }.bind(this));
    },

    /**
     * Callback function for when the "Show notification"-button is clicked
     * This callback is atteched to element via data-event-attribute in html
     * @param {jQuery.Event} e jQuery Click-event
     */
    showNotificationClicked: function (e) {
        e.preventDefault();
        this.writeLog('@showNotificationClicked');
        Notify.info('Information', 'You clicked a button...');
    },

    /**
     * Callback function for when the "Show confirm"-button is clicked
     * This callback is atteched to element via data-event-attribute in html
     * @param {jQuery.Event} e jQuery Click-event
     */
    showConfirmClicked: function (e) {
        e.preventDefault();
        this.writeLog('@showConfirmClicked');
        Confirm('Are you sure?', 'Are you sure you want to do this?').then(function () {
            this.writeLog('Confirm: true');
        }.bind(this), function () {
            this.writeLog('Confirm: false');
        }.bind(this));
    },

    /**
     * Helper to write output log
     * @param {String} message Message to log
     */
    writeLog: function (message) {
        this.hooks.outputWindow.prepend(message + '<br/>');
    }
});
