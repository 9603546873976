require('./polyfills/promise');
require('./polyfills/nodelist');
require('./polyfills/object.assign');
require('./polyfills/classList');

window.Views = {
    Example: require('./views/example'),
    Dashboard: require('./views/dashboard'),
    Integration: require('./views/integration'),
    Invoicing: {
        RowsEdit: require('../../../maintenance/frontend/js/views/reporting-rows-edit')
    }
};
