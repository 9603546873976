/*globals require, define, module, console*/
/*jslint nomen: true*/
(function (root, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jQuery'], function (jQuery) {
            return (root.Block = factory(jQuery));
        });
    } else if (typeof module === 'object' && module.exports) {
        module.exports = (root.Block = factory((typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null)));
    } else {
        root.Block = factory(root.jQuery);
    }
}(this, function (jQuery) {
    'use strict';

    /*  ###########################
        Helpers
        ########################### */
    function copyArguments() {
        var args = new Array(arguments.length),
            index = 0,
            len = 0;
        for (index = 0, len = args.length; index < len; index += 1) {
            args[index] = arguments[index];
        }
        return args;
    }

    /*  ############################
        Actual module definiton
        ############################ */
    function BaseBlock() {
        var defer = jQuery.Deferred(),
            $el = null,
            val = null,
            evtName = null,
            evtCbName = null,
            evtDef = null;

        this.loader = defer.promise();
        this._meta = { events: { cache : {}, listeners: {} } };
        this._evtBound = [];
        this._listeners = {};
        this.hooks = {};

        if (!this.template) {
            if (this.templateSrc) {
                if (typeof (this.templateSrc) !== 'string') {
                    this.templateSrc = this.templateSrc.apply(this, arguments);
                }
                jQuery.get(this.templateSrc).then(function (template) {
                    defer.resolve(template);
                }, function (e, jqxhr, settings, thrownError) {
                    defer.reject(new Error(thrownError || 'Unable to load template'));
                });

            } else {
                defer.reject(new Error('Missing template'));
            }
        } else {
            defer.resolve(this.template);
        }

        this.registerEventHooks = function() {
            // Find all events
            this.$el.find('*[data-event]').not('[data-block] *[data-hook]').each(function (i, e) {
                $el = jQuery(e);
                val = $el.data('event');
                if (val) {
                    val.trim().split(/\s+/g).forEach(function (evtSpec) {
                        evtDef = evtSpec.split('@');
                        if (evtDef.length === 2) {
                            evtName = evtDef[0];
                            evtCbName = evtDef[1];
                            if (this.hasOwnProperty(evtCbName)) {
                                this._evtBound.push({
                                    el: $el,
                                    event: evtName
                                });
                                $el.on(evtName, this[evtCbName].bind(this));
                            } else {
                                throw new Error('Event callback missing: ' + evtCbName);
                            }
                        }
                    }, this);
                }
                
                // Prevents re-registering
                e.removeAttribute('data-event');
            }.bind(this));
        }.bind(this);
        
        this.registerHooks = function() {
            // Find all hooks
            this.$el.find('*[data-hook]').not('[data-block] *[data-hook]').each(function (i, e) {
                $el = jQuery(e);
                val = $el.data('hook');
                if (!val) {
                    val = $el.attr('id');
                }
                if (val) {
                    this.hooks[val] = $el;
                }
            }.bind(this));

            this.registerEventHooks();
        }.bind(this);

        this.loader.then(function (template) {
            this.template = template;
            this.$el = this.template instanceof jQuery ? this.template : jQuery(this.template);
            if (this.$el.length > 1) {
                console.warn('Root should be pointing to a single element');
            }

            this.$el.removeAttr('data-block');

            this.registerHooks();
            
        }.bind(this), function (e) {
            throw e;
        });
    }
    BaseBlock.extend = function (props, Parent) {
        if (!Parent) {
            Parent = BaseBlock;
        }
        var Block = function () {
            this.super = {};
            var propKey = null,
                args = null;
            for (propKey in props) {
                if (props.hasOwnProperty(propKey)) {
                    if (this.hasOwnProperty(propKey)) {
                        if (this[propKey].bind) {
                            this.super[propKey] = this[propKey].bind(this);
                        } else {
                            this.super[propKey] = this[propKey];
                        }
                    }
                    this[propKey] = props[propKey];
                }
            }
            Parent.apply(this, arguments);
            if (props.hasOwnProperty('init')) {
                args = copyArguments.apply(this, arguments);
                this.loader.done(function () {
                    props.init.apply(this, args);
                }.bind(this));
            }
        };

        Block.extend = function (props) {
            return BaseBlock.extend(props, Block);
        };
        Block.forElem = function (elem, args) {
            var b = Block.extend({
                template: elem
            });
            var obj = {};
            b.apply(obj, args);
            return obj;
        };

        Block.prototype = Object.create(Parent.prototype);
        Block.prototype.constructor = Parent.constructor;
        return Block;
    };

    BaseBlock.prototype.remove = function () {
        // Remove element from DOM
        this.$el.remove();

        // Unbind event listeners
        this._evtBound.forEach(function (evt) {
            evt.el.off(evt.name);
        });

        // Nofity any listeners
        this.emit('block:removed', this);
    };

    BaseBlock.prototype.renderInto = function (selector) {
        if (selector instanceof BaseBlock) {
            selector = selector.$el;
        } else if (!(selector instanceof jQuery)) {
            selector = jQuery(selector);
        }
        selector.append(this.$el);

        // Notify listeners
        this.emit('block:rendered', this);
    };

    BaseBlock.prototype.on = function (event, callback, context) {
        if (event && callback) {
            if (!this._meta.events.listeners.hasOwnProperty(event)) {
                this._meta.events.listeners[event] = [];
            }
            this._meta.events.listeners[event].push({ cb: callback, ctx: context || this});
        } else {
            throw new Error('event and callback are required for Block#off');
        }
    };

    BaseBlock.prototype.off = function (event, callback) {
        if (event && callback) {
            if (this._meta.events.listeners.hasOwnProperty(event)) {
                var events = this._meta.events.listeners[event],
                    len = events.length,
                    index = 0,
                    evt = null;
                for (index = 0; index < len; index += 1) {
                    evt = this._meta.events.listeners[event][index];
                    if (evt.cb === callback) {
                        delete this._meta.events.listeners[event][index];
                        break;
                    }
                }
            }
        } else {
            throw new Error('event and callback are required for Block#off');
        }
    };

    BaseBlock.prototype.emit = function (event) {
        if (this._meta.events.listeners.hasOwnProperty(event)) {
            var args = copyArguments.apply(this, arguments);
            args.shift(); // Remove 'event' from arguments list
            this._meta.events.listeners[event].forEach(function (evt) {
                evt.cb.apply(evt.ctx, args);
            });
        }
    };

    return BaseBlock;
}));
