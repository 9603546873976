/*globals TCApi, $ */
'use strict';
var merge = require('merge-options');
var Block = require('../libs/block');
var PAGE_REFRESH_INTERVAL = 60000; // In ms

var chartCommonOptions = {
    colors: ['#0066ff', '#001f4d', '#3f51b5', '#2196f3', '#00bcd4', '#009688', '#4caf50', '#673ab7', '#9c27b0', '#607d8b', '#9e9e9e'],
};

var barCharCommonOptions = merge({}, chartCommonOptions, {
    chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
            show: false,
        }
    },
    legend: {
        horizontalAlign: 'left',
        position: 'top',
    },
    plotOptions: {
        bar: {
            horizontal: false,
        },
    },
});
var pieChartCommonProperties = merge({}, chartCommonOptions, {
    chart: {
        type: 'pie',
        width: '100%',
        height: '400px',
    },
    tooltip: {
        enabled: true,
    },
    legend: {
        show: true,
        horizontalAlign: 'left',
        position: 'top',
        fontSize: '11px',
        itemMargin: {
            vertical: 0,
        },
        markers: {
            width: '10px',
            height: '10px',
            radius: 0,
        },
    },
    dataLabels: {
        // Custom formatter because apexcharts internally
        // ends up displaying 99.96 as 100% ... :/
        formatter: function(val, opts) {
            return [val.toFixed(2), '%'].join('');
        },
    },
    plotOptions: {
        pie: {
            dataLabels: {
                minAngleToShowLabel: 15,
              //  offset: -10,
            }
        },
    },
    stroke: {
        show: true,
    }
});

var pieChartOptionsNonInteractive = {
    tooltip: {
        enabled: false,
    },
    legend: {
        show: false,
    },
    plotOptions: {
        pie: {
            dataLabels: {
              minAngleToShowLabel: Number.MAX_VALUE
            }
        }
    },
    stroke: {
        show: false,
    }
};

var pieChartCommonPropertiesNoData = merge({}, pieChartCommonProperties, pieChartOptionsNonInteractive, {
    chart: {
        height: 'auto',
        width: '120%',
    },
});

function pieChart(element, chartOptions, noPlaceholder) {
    // All values are zeroes. Instead of displaying a blank chart, display a grey placeholder chart
    var nonZeroValueCount = chartOptions.series.filter(function(s) {return s != 0}).length;

    chartOptions = merge({}, pieChartCommonProperties, chartOptions);
    if (nonZeroValueCount <= 1) {
        chartOptions.stroke.show = false;
    }
    console.log(element.id, chartOptions);
    var chart = new ApexCharts(element, chartOptions);
    chart.render();

    // Overly complex dummy placeholder pie chart 
    // because apexcharts does not support it; just noData text label
    if (nonZeroValueCount == 0 && !noPlaceholder) {
        chartOptions.chart.height = 'auto';
        var placeholderChartElement = document.createElement('div');
        element.parentElement.style.position = 'relative';
        placeholderChartElement.style.position = 'absolute';
        placeholderChartElement.style.top = '50%';
        placeholderChartElement.style.transform = 'translateY(-50%) translateX(-10%)';
        element.parentElement.appendChild(placeholderChartElement);
        new ApexCharts(placeholderChartElement, merge({}, pieChartCommonPropertiesNoData, {
            series: [1],
            colors: ['rgba(0, 0, 0, 0.075)']
        })).render();
    }
    return chart;
}

function barChart(element, chartOptions) {
    var chart = new ApexCharts(element, merge({}, barCharCommonOptions, chartOptions));
    chart.render();
    return chart;
}

module.exports = Block.extend({
    template: '#dashboard',
    range: 0,

    // wait_datasets: [{
    //     label: $('#dashboard').data('average_delay'),
    //     color: '#003F9E',
    //     highlightColor: '#003F9E',
    //     lines: { show: false, steps: false },
    //     bars: { show: true, align: 'center', fill: 0.9 }
    // }],
    //
    // delay_datasets: [{
    //     label: $('#dashboard').data('order_types'),
    //     color: '#003F9E',
    //     highlightColor: '#003F9E',
    //     lines: { show: false, steps: false },
    //     bars: { show: true, align: 'center', fill: 0.9 }
    // }],
    //
    // orders_datasets: [{
    //     stack: 0,
    //     label: $('#dashboard').data('scheduled_orders'),
    //     color: '#00B226',
    //     highlightColor: '#00B226',
    //     lines: { show: false, steps: false },
    //     bars: { show: true, align: 'center', fill: 0.9 }
    // }, {
    //     stack: 0,
    //     label: $('#dashboard').data('unscheduled_orders'),
    //     color: '#A91500',
    //     highlightColor: '#A91500',
    //     lines: { show: false, steps: false },
    //     bars: { show: true, align: 'center', fill: 0.9 }
    // }],
    //
    // chart_options_wait: {
    //     xaxis: {
    //         axisLabelUseCanvas: true,
    //         axisLabelFontSizePixels: 12,
    //         axisLabelFontFamily: 'Arial',
    //         axisLabelPadding: 3,
    //         color: '#d5d5d5',
    //         tickLength: 0
    //     },
    //     yaxis: {
    //         position: 'left',
    //         color: '#d5d5d5',
    //         minTickSize: 1,
    //         axisLabelUseCanvas: true,
    //         axisLabelFontSizePixels: 12,
    //         axisLabelFontFamily: 'Arial',
    //         axisLabelPadding: 3,
    //         min: 0
    //     },
    //     legend: {
    //         show: false
    //     },
    //     grid: {
    //         hoverable: true,
    //         clickable: false,
    //         borderWidth: 0,
    //         margin: {
    //             bottom: 30
    //         }
    //     }
    // },
    //
    // chart_options_delay: {
    //     xaxis: {
    //         axisLabelUseCanvas: true,
    //         axisLabelFontSizePixels: 12,
    //         axisLabelFontFamily: 'Arial',
    //         axisLabelPadding: 3,
    //         color: '#d5d5d5',
    //         tickLength: 0
    //     },
    //     yaxis: {
    //         position: 'left',
    //         color: '#d5d5d5',
    //         minTickSize: 1,
    //         axisLabelUseCanvas: true,
    //         axisLabelFontSizePixels: 12,
    //         axisLabelFontFamily: 'Arial',
    //         axisLabelPadding: 3,
    //         min: 0
    //     },
    //     legend: {
    //         show: false
    //     },
    //     grid: {
    //         hoverable: true,
    //         clickable: false,
    //         borderWidth: 0
    //     }
    // },
    //
    // chart_options_orders: {
    //     xaxis: {
    //         axisLabelUseCanvas: true,
    //         axisLabelFontSizePixels: 12,
    //         axisLabelFontFamily: 'Arial',
    //         axisLabelPadding: 6,
    //         color: '#d5d5d5',
    //         tickLength: 0
    //     },
    //     yaxis: {
    //         position: 'left',
    //         color: '#d5d5d5',
    //         minTickSize: 1,
    //         axisLabelUseCanvas: true,
    //         axisLabelFontSizePixels: 12,
    //         axisLabelFontFamily: 'Arial',
    //         axisLabelPadding: 3,
    //         min: 0
    //     },
    //     legend: {
    //         container:$('#orders-legend-container')
    //     },
    //     grid: {
    //         hoverable: true,
    //         clickable: false,
    //         borderWidth: 0,
    //         margin: {
    //             left: 30,
    //             bottom: 30
    //         }
    //     }
    // },

    init: function () {
        this.refreshCharts('full');
        $(window).resize(function(){
            this.refreshCharts('refresh');
        }.bind(this));
    },

    refreshCharts: function(refreshType) {
        var chartBoxSelector = $('.js-div-order-chart-box'),
            orderBoxesSelector = $('.db-div-order-box');

        orderBoxesSelector.height(chartBoxSelector.height());
        // ApexCharts based charts are capable of resizing on window resize natively
        if (refreshType == 'full') {
            this.updateInvoicingChart();
            this.updateProvisioningChart();
            this.updateOnScheduleChart();
            this.updateCompletedOrdersChart();
            this.updateInvoicedOrdersChart();
            this.updateInvoicedWorkingHoursChart();
        }
    },

    updateCompletedOrdersChart: function() {
        var selector = $('#db-completed-orders-month-chart');
        pieChart(document.querySelector('#db-completed-orders-month-chart'), {
            series: [
                selector.data('installations-count'),
                selector.data('modifications-count'),
                selector.data('insp-maintenances-count'),
                selector.data('sched-maintenances-count'),
                selector.data('repairs-count'),
                selector.data('order-reports-count'),
            ],
            labels: [
                selector.data('installations-label'),
                selector.data('modifications-label'),
                selector.data('inspections-label'), 
                selector.data('scheduled-label'),
                selector.data('repairs-label'),
                selector.data('order-reports-label'),
            ],
            colors: [
                selector.data('installations-color'),
                selector.data('modifications-color'),
                selector.data('insp-maintenances-color'),
                selector.data('sched-maintenances-color'),
                selector.data('repairs-color'),
                selector.data('order-reports-color'),
            ],
        });
    },
    updateInvoicedWorkingHoursChart: function() {
        var chartData = JSON.parse(document.getElementById('db-invoiced-working-hours-month-chart').getAttribute('data-stats'));
        pieChart(document.querySelector('#db-invoiced-working-hours-month-chart'), {
            series: chartData.map(function(o) { return o.data; }),
            labels: chartData.map(function(o) { return o.label; }),
            colors: chartData.map(function(o) { return o.color; }),
        });
    },
    updateInvoicedOrdersChart: function() {
        var selector = $('#db-invoiced-orders-month-chart');
        pieChart(document.querySelector('#db-invoiced-orders-month-chart'), {
            series: [
                selector.data('installations-count'),
                selector.data('modifications-count'),
                selector.data('insp-maintenances-count'),
                selector.data('sched-maintenances-count'),
                selector.data('repairs-count'),
                selector.data('order-reports-count'),
            ],
            labels: [
                selector.data('installations-label'),
                selector.data('modifications-label'),
                selector.data('inspections-label'),
                selector.data('scheduled-label'),
                selector.data('repairs-label'), 
                selector.data('order-reports-label'),
            ], 
            colors: [
                selector.data('installations-color'),
                selector.data('modifications-color'),
                selector.data('insp-maintenances-color'),
                selector.data('sched-maintenances-color'),
                selector.data('repairs-color'),
                selector.data('order-reports-color'),
            ]
        });
    },

    updateOnScheduleChart: function() {
        var element = document.querySelector('#db-on-schedule-chart');
        var totalOrders = parseFloat($('#total-order-count').text());
        var unscheduledOrders = parseFloat($('#unscheduled-order-count').text());
        var urls = [
            element.getAttribute('data-url-red'),
            element.getAttribute('data-url-yellow'),
            element.getAttribute('data-url-green'),
            element.getAttribute('data-url-other'),
        ];
        var urlContract = element.getAttribute('data-url-other-contract');
        var urlContractless = element.getAttribute('data-url-other-contractless');

        var red = Number(element.getAttribute('data-value-red'));
        var yellow = Number(element.getAttribute('data-value-yellow'));
        var green = Number(element.getAttribute('data-value-green'));

        var contract = Number(element.getAttribute('data-value-other'));
        var contractless = Number(element.getAttribute('data-value-contractless'));
        
        var checkboxContract = document.getElementById('db-toggle-maintenance-order-stats-contract');
        var checkboxContractless = document.getElementById('db-toggle-maintenance-order-stats-contractless');

        var hideBlankLabel = document.createElement('style');
        hideBlankLabel.textContent = '#db-on-schedule-chart .apexcharts-legend-series[rel="5"] { display: none !important; }';
        document.head.appendChild(hideBlankLabel);
        var baseOptions = {
            series: [
                0, 0, 0, 0
            ],
            labels: [
                element.getAttribute('data-label-red'),
                element.getAttribute('data-label-yellow'),
                element.getAttribute('data-label-green'),
                element.getAttribute('data-label-other'),
            ],
            colors: [
                '#f44336',
                '#ffc107',
                '#4caf50',
                '#9e9e9e',
                'rgba(0, 0, 0, 0.075)',
            ],
            chart: {
                events: {
                    dataPointSelection: function(event, chartContext, config) {
                        // Clicking on a label results in the event being null
                        // we only want to open the order list when a sector of the pie chart is clicked
                        // not all data sets have URLs
                        var url = urls[config.dataPointIndex];
                        // If the user clicked "other" and either filter checkbox is not checked, we have to choose another URL
                        if (config.dataPointIndex == 3 && (!checkboxContract.checked || !checkboxContractless.checked)) {
                            if (!checkboxContract.checked && !checkboxContractless.checked) {
                                url = null;
                            } else if (checkboxContract.checked) {
                                url = urlContract;
                            } else if (checkboxContractless.checked) {
                                url = urlContractless;
                            }
                        }
                        if (event == null || !url) {
                            return;
                        }
                        
                        window.open(url, '_blank').focus();
                    },
                    dataPointMouseEnter: function(event) {
                        event.path[0].style.cursor = "pointer";
                    }
                }
            }
        };
        var chartOptionsWithData = merge({}, pieChartCommonProperties, baseOptions);
        var chartOptionsWithoutData = merge({}, pieChartCommonProperties, pieChartOptionsNonInteractive, baseOptions);

        var chart = pieChart(element, baseOptions, true);
        var restoreHeightHandle = null;

        var updateChart = function() {
            var series = [];
            var other = 0;

            if (checkboxContract.checked) {
                other += contract;
            }
            if (checkboxContractless.checked) {
                other += contractless; 
            }
            var noData = (!checkboxContract.checked && !checkboxContractless.checked);
            var series = [
                checkboxContract.checked ? red : 0,
                checkboxContract.checked ? yellow : 0,
                checkboxContract.checked ? green : 0,
                other,
            ];
            document.getElementById('total-order-count').textContent = series.reduce(function(a, b) { return a + b}, 0);
            if (noData) {
                series.push(1);
            }
            // animations cause the bounding boxes of the chart expand and contract; we'll lock it in place for a second as a "solid" fix
            // cancel any previously queued fixed height resets in case the user
            // to support multiple rapid updates
            element.parentElement.style.maxHeight = [element.parentElement.offsetHeight, 'px'].join('');
            chart.updateOptions(merge({}, noData ? chartOptionsWithoutData : chartOptionsWithData, {
                series: series,
                // More than one non-zero series to display
                stroke: {
                    show: series.filter(function(s) { return s != 0; }).length > 1,
                },
                // Legend is always visible
                legend: {
                    show: true
                }
            }));
            chart.windowResizeHandler();
            clearTimeout(restoreHeightHandle);
            restoreHeightHandle = setTimeout(function() { element.parentElement.style.maxHeight = null; }, 1000);
            
        };
        updateChart();
        checkboxContract.addEventListener('change', updateChart);
        checkboxContractless.addEventListener('change', updateChart);
    },

    updateProvisioningChart: function() {
        var element = document.querySelector('#db-provisioning-chart');
        var target = element.getAttribute('data-target');
        var realized = JSON.parse(element.getAttribute('data-realized'));
        var series = [];
        var labels = [];
        for (var key in realized) {
            series.push(realized[key]);
            labels.push(key);
        }
        pieChart(element, {
            series: series,
            labels: labels
        })
        /*barChart(element, {
            series: series,
            xaxis: {
                categories: [
                    // https://git.bluugo.fi/europress/CustomerSite/-/issues/861#note_39483
                    // element.getAttribute('data-label-target'), 
                    element.getAttribute('data-label-realized')
                ]
            },
            dataLabels: {
                formatter: function(val, opts) {
                    return [val.toFixed(2)].join('');
                },
            },
        });*/
    },

    updateInvoicingChart: function() {
        var target = document.getElementById('target-invoicing-count');
        var current = document.getElementById('realised-invoicing-count');
        var element = document.querySelector('#db-invoicing-chart');
        var hiddenLegends = [
            target.getAttribute('data-fs-service-leasing-maintenance-target-label'),
            target.getAttribute('data-fs-repairs-target-label'),
            target.getAttribute('data-basic-warranty-target-label'),
            target.getAttribute('data-additional-warranty-target-label'),
            target.getAttribute('data-internal-sales-target-label'),
            target.getAttribute('data-internal-service-target-label'),
            target.getAttribute('data-external-target-label')
        ];
        var checkboxToggleSelectAll = document.getElementById('db-toggle-invoicing-select-all');

        var chart = barChart(element, {
            series: [
                {id:'fs-service', invoiceType:'current', name: current.getAttribute('data-fs-service-leasing-maintenance-label'), data: [0, Number(current.getAttribute('data-fs-service-leasing-maintenance-value'))]},
                {id:'fs-repairs', invoiceType:'current', name: current.getAttribute('data-fs-repairs-label'), data: [0, Number(current.getAttribute('data-fs-repairs-value'))]},
                {id:'basic-warranty', invoiceType:'current', name: current.getAttribute('data-basic-warranty-label'), data: [0, Number(current.getAttribute('data-basic-warranty-value'))]},
                {id:'additional-warranty', invoiceType:'current', name: current.getAttribute('data-additional-warranty-label'), data: [0, Number(current.getAttribute('data-additional-warranty-value'))]},
                {id:'internal-sales', invoiceType:'current', name: current.getAttribute('data-internal-sales-label'), data: [0, Number(current.getAttribute('data-internal-sales-value'))]},
                {id:'internal-service', invoiceType:'current', name: current.getAttribute('data-internal-service-label'), data: [0, Number(current.getAttribute('data-internal-service-value'))]},
                {id:'external', invoiceType:'current', name: current.getAttribute('data-external-label'), data: [0, Number(current.getAttribute('data-external-value'))]},
                
                // Re-use colors from beginning of chartCommonOption colors to have same color in target as in realised values
                {color: chartCommonOptions.colors[0], id:'fs-service', invoiceType:'target', name: target.getAttribute('data-fs-service-leasing-maintenance-target-label'), data: [Number(target.getAttribute('data-fs-service-leasing-maintenance-target-value')), 0]},
                {color: chartCommonOptions.colors[1], id:'fs-repairs', invoiceType:'target', name: target.getAttribute('data-fs-repairs-target-label'), data: [Number(target.getAttribute('data-fs-repairs-target-value')), 0]},
                {color: chartCommonOptions.colors[2], id:'basic-warranty', invoiceType:'target', name: target.getAttribute('data-basic-warranty-target-label'), data: [Number(target.getAttribute('data-basic-warranty-target-value')), 0]},
                {color: chartCommonOptions.colors[3], id:'additional-warranty', invoiceType:'target', name: target.getAttribute('data-additional-warranty-target-label'), data: [Number(target.getAttribute('data-additional-warranty-target-value')), 0]},
                {color: chartCommonOptions.colors[4], id:'internal-sales', invoiceType:'target', name: target.getAttribute('data-internal-sales-target-label'), data: [Number(target.getAttribute('data-internal-sales-target-value')), 0]},
                {color: chartCommonOptions.colors[5], id:'internal-service', invoiceType:'target', name: target.getAttribute('data-internal-service-target-label'), data: [Number(target.getAttribute('data-internal-service-target-value')), 0]},
                {color: chartCommonOptions.colors[6], id:'external', invoiceType:'target', name: target.getAttribute('data-external-target-label'), data: [Number(target.getAttribute('data-external-target-value')), 0]},
            ],
            xaxis: {
                categories: [target.getAttribute('data-label'), current.getAttribute('data-label')]
            },
            legend: {
                formatter: function(seriesName, opts) {
                    if (hiddenLegends.includes(seriesName)) {
                        return null;
                    }
                    return seriesName;
                },
                markers: {
                    width: [12,12,12,12,12,12,12,0,0,0,0,0,0]
                }
            },
            chart: {
                events: {
                    legendClick: function(chartContext, seriesIndex, config) {
                        var series = config.config.series[seriesIndex];

                        if (series.invoiceType == 'current') {
                            var targetSeries = config.config.series.filter(function(s) {
                                return s.invoiceType == 'target' && s.id == series.id;
                            })
                            chart.toggleSeries(targetSeries[0].name);
                        }
                    },
                    updated: function(chartContext, config) {
                        var totalTarget = 0;
                        var totalCurrent = 0;
                        for (var i = 0; i < config.config.series.length; i++) {
                            if (config.config.series[i].data.length) {
                                if (config.config.series[i].invoiceType == 'target') {
                                    totalTarget += config.config.series[i].data[0];
                                } else if (config.config.series[i].invoiceType == 'current') {
                                    totalCurrent += config.config.series[i].data[1];
                                }
                            }
                            var targetElement = document.getElementById('target-total-value');
                            var currentElement = document.getElementById('current-total-value');
                            if (targetElement != null) {
                                targetElement.innerHTML = numberWithSeparator(totalTarget);
                            }
                            if (currentElement != null) {
                                currentElement.innerHTML = numberWithSeparator(parseFloat(totalCurrent).toFixed(0));
                            }
                        }
                    }
                }
            }
        });

    var numberWithSeparator = function (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;');
    }

    var toggleInvoicingSeries = function (showSeries) {
        if (showSeries === true) {
            chart.showSeries(current.getAttribute('data-external-label'));
            chart.showSeries(current.getAttribute('data-fs-service-leasing-maintenance-label'));
            chart.showSeries(current.getAttribute('data-fs-repairs-label'));
            chart.showSeries(current.getAttribute('data-basic-warranty-label'));
            chart.showSeries(current.getAttribute('data-additional-warranty-label'));
            chart.showSeries(current.getAttribute('data-internal-sales-label'));
            chart.showSeries(current.getAttribute('data-internal-service-label'));

            chart.showSeries(target.getAttribute('data-external-target-label'));
            chart.showSeries(target.getAttribute('data-fs-service-leasing-maintenance-target-label'));
            chart.showSeries(target.getAttribute('data-fs-repairs-target-label'));
            chart.showSeries(target.getAttribute('data-basic-warranty-target-label'));
            chart.showSeries(target.getAttribute('data-additional-warranty-target-label'));
            chart.showSeries(target.getAttribute('data-internal-sales-target-label'));
            chart.showSeries(target.getAttribute('data-internal-service-target-label'));
        } else {
            chart.hideSeries(current.getAttribute('data-external-label'));
            chart.hideSeries(current.getAttribute('data-fs-service-leasing-maintenance-label'));
            chart.hideSeries(current.getAttribute('data-fs-repairs-label'));
            chart.hideSeries(current.getAttribute('data-basic-warranty-label'));
            chart.hideSeries(current.getAttribute('data-additional-warranty-label'));
            chart.hideSeries(current.getAttribute('data-internal-sales-label'));
            chart.hideSeries(current.getAttribute('data-internal-service-label'));

            chart.hideSeries(target.getAttribute('data-external-target-label'));
            chart.hideSeries(target.getAttribute('data-fs-service-leasing-maintenance-target-label'));
            chart.hideSeries(target.getAttribute('data-fs-repairs-target-label'));
            chart.hideSeries(target.getAttribute('data-basic-warranty-target-label'));
            chart.hideSeries(target.getAttribute('data-additional-warranty-target-label'));
            chart.hideSeries(target.getAttribute('data-internal-sales-target-label'));
            chart.hideSeries(target.getAttribute('data-internal-service-target-label'));
        }
    }

        toggleInvoicingSeries(false);
        chart.showSeries(current.getAttribute('data-external-label'));
        chart.showSeries(target.getAttribute('data-external-target-label'));

        checkboxToggleSelectAll.addEventListener('change', function(event) {
            toggleInvoicingSeries(event.target.checked);
        });
    },
    // updateDelayChart: function() {
    //     // Another bug in the flot chart: Bar width is not working with only one tick on x-axis, need to create dummies
    //     var selector = $('#dashboard');
    //     TCApi.call('update_delay_chart', {
    //         args: {
    //             customer: selector.data('customer'),
    //             location: selector.data('location')
    //         }
    //     }).then(function(res) {
    //         this.chart_options_wait.xaxis.ticks = [[0, ''], [1, res.customer], [2, '']];
    //         if (res.waiting_time < 0) {
    //             this.chart_options_wait.yaxis.min = res.waiting_time;
    //         }
    //         this.wait_datasets[0].bars.barWidth = 0.5;
    //         this.wait_datasets[0].data = [[0, null], [1, res.waiting_time], [2, null]];
    //         $.plot($('#flot-waiting-time-chart'), this.wait_datasets, this.chart_options_wait);
    //     }.bind(this));
    // },
    //
    // updateTypesChart: function() {
    //     var selector = $('#dashboard');
    //     TCApi.call('update_types_chart', {
    //         args: {
    //             customer: selector.data('customer'),
    //             location: selector.data('location')
    //         }
    //     }).then(function(res) {
    //         this.chart_options_delay.xaxis.ticks = res.types_list;
    //         this.types_list = res.types_list;
    //         this.delay_datasets[0].bars.barWidth = 0.2;
    //         this.delay_datasets[0].data = res.data;
    //         $.plot($('#flot-delay-chart'), this.delay_datasets, this.chart_options_delay);
    //     }.bind(this));
    // },
    //
    // updateOrderChart: function(range) {
    //     var date = new Date(),
    //         thisYear = date.getFullYear(),
    //         thisMonth = date.getMonth(),
    //         firstDay = new Date(thisYear, thisMonth, 1),
    //         lastDay = new Date(thisYear, thisMonth + 1, 0),
    //         selector = $('#dashboard');
    //
    //     TCApi.call('update_orders_chart', {
    //         args: {
    //             time_range: range,
    //             location: selector.data('location'),
    //             customer: selector.data('customer')
    //         }}).then(function(res) {
    //             this.chart_options_orders.xaxis.min = res.date_from;
    //             this.chart_options_orders.xaxis.max = res.date_to;
    //             this.orders_datasets[0].data = res.data0;
    //             this.orders_datasets[1].data = res.data1;
    //             switch (range) {
    //                 case 0:
    //                     this.orders_datasets[0].bars.barWidth =
    //                     this.orders_datasets[1].bars.barWidth = 24 * 60 * 60 * 450;
    //                     this.chart_options_orders.xaxis.mode = 'time';
    //                     this.chart_options_orders.xaxis.tickSize = [4, 'day'];
    //                     this.chart_options_orders.xaxis.tickFormatter = function (val, axis) {
    //                         var d = new Date(val);
    //                         return d.getUTCDate() + "." + (d.getUTCMonth() + 1);
    //                     }.bind(this);
    //                     break;
    //                 case 1:
    //                     this.orders_datasets[0].bars.barWidth =
    //                     this.orders_datasets[1].bars.barWidth = 0.7;
    //                     this.chart_options_orders.xaxis.mode = null;
    //                     this.chart_options_orders.xaxis.tickSize = 4;
    //                     this.chart_options_orders.xaxis.tickFormatter = function (val, axis) {
    //                         if (val === 0 || val === 53) {
    //                             return '';
    //                         } else {
    //                             return val;
    //                         }
    //                     }.bind(this);
    //                     break;
    //                 case 2:
    //                     this.orders_datasets[0].bars.barWidth =
    //                     this.orders_datasets[1].bars.barWidth = 0.3;
    //                     this.chart_options_orders.xaxis.mode = null;
    //                     this.chart_options_orders.xaxis.tickSize = 1;
    //                     this.chart_options_orders.xaxis.tickFormatter = function (val, axis) {
    //                         var months = $('#dashboard').data('monthshort').split(',');
    //                         var axisText = '';
    //                         if (months[val-1] !== undefined) {
    //                             axisText = months[val-1];
    //                         }
    //                         return axisText;
    //                     }.bind(this);
    //                     break;
    //             }
    //
    //             this.hooks.ordersChartContainer.removeClass('hidden');
    //             this.hooks.emptyOrdersChartNotify.addClass('hidden');
    //
    //             $.plot($('#flot-orders-chart'), this.orders_datasets, this.chart_options_orders);
    //         }.bind(this)
    //     );
    // },
    //
    // ordersChartRangeChanged: function (e) {
    //     var button = $(e.target);
    //     button.siblings().removeClass('btn-primary').addClass('btn-white');
    //     button.addClass('btn-primary').removeClass('btn-white');
    //     this.range = button.data('range');
    //     this.updateOrderChart(this.range);
    // },

    // showOrdersTooltip: function(event, pos, item) {
    //     event.preventDefault();
    //     var tooltipElement = $('#orders-chart-tooltip'),
    //         selector = $('#dashboard');
    //
    //     if (item) {
    //         var x = item.datapoint[0].toFixed(0),
    //             y = item.datapoint[1] - item.datapoint[2].toFixed(0),
    //             text;
    //         switch (item.seriesIndex) {
    //             case 0:
    //                 text = selector.data('scheduled_orders');
    //                 break;
    //             case 1:
    //                 text = selector.data('unscheduled_orders');
    //                 break;
    //         }
    //         switch (this.range) {
    //             case 0:
    //                 var d = new Date(parseInt(x)),
    //                     t = d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
    //                 break;
    //             case 1:
    //                 t = selector.data('week') +  parseInt(x);
    //                 break;
    //             case 2:
    //                 var months = selector.data('monthlong').split(',');
    //                 t = months[parseInt(x)-1];
    //                 break;
    //         }
    //         tooltipElement.html(t + ':<br/>' + text + ': ' + y);
    //     }
    // },
    //
    // showWaitTooltip: function(event, pos, item) {
    //     event.preventDefault();
    //     var tooltipElement = $('#waiting-time-chart-tooltip');
    //
    //     var x = 0,
    //         y = 0,
    //         selector = $('#dashboard'),
    //         text = selector.data('average_delay'),
    //         t = '';
    //
    //     if (item) {
    //         x = item.datapoint[0].toFixed(0);
    //         y = item.datapoint[1].toFixed(0);
    //
    //         if (y < 0) {
    //             text = selector.data('average_advance');
    //             y = 0 - y;
    //         }
    //         tooltipElement.html('<br>' + text + ': ' + y + selector.data('minutes'));
    //     }
    // },
    //
    // showDelayTooltip: function(event, pos, item) {
    //     event.preventDefault();
    //     var tooltipElement = $('#delay-chart-tooltip');
    //
    //     var x = 0,
    //         y = 0,
    //         selector = $('#dashboard'),
    //         text = selector.data('order_type'),
    //         t = '';
    //
    //     if (item) {
    //         x = item.datapoint[0].toFixed(0);
    //         y = item.datapoint[1].toFixed(0);
    //         t = this.types_list[x][1];
    //         tooltipElement.html(text + '<br/>' + t + ': ' + y + selector.data('orders'));
    //     }
    // }
});